import Text, {
    TextDomTag,
} from 'client/widget-components/basicComponents/Text';
import React from 'react';
import { RuntimeMediaQuery } from '@duda-co/responsive-styles/RuntimeMediaQuery';
import type { ResponsiveStylesStructured } from '@duda-co/responsive-styles/types/ResponsiveStylesTypes';
import { CharCounterProps } from '../types';

const BaseCharCounter = ({
    valueLength,
    dataGrab,
    styles,
    dataAuto = 'char-counter',
}: CharCounterProps) => {
    return (
        <Text
            styles={[counterDefaultStyles, styles]}
            data-auto={dataAuto}
            data-grab={dataGrab}
            tag={TextDomTag.paragraph}
        >
            {valueLength}
        </Text>
    );
};

export default BaseCharCounter;

const counterDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        margin: 0,
        boxSizing: 'border-box',
    },
};
