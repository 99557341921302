import { RuntimeMediaQuery } from '@duda-co/responsive-styles/RuntimeMediaQuery';
import type { ResponsiveStylesStructured } from '@duda-co/responsive-styles/types/ResponsiveStylesTypes';

export const textFieldWrapperDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        boxSizing: 'border-box',
    },
};
export const labelWrapperDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '8px',
        boxSizing: 'border-box',
        direction: 'inherit',
    },
};
export const textareaDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        width: '100%',
        padding: '8px',
        height: '86px',
        boxSizing: 'border-box',
        borderRadius: '3px',
        border: '1px solid rgba(201, 202, 205, 1)',
    },
};
export const placeholderDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        fontFamily: 'Open Sans',
        fontSize: '11px',
        fontWeight: '400',
        lineHeight: '22px',
        color: 'rgba(130, 136, 148, 1)',
    },
};
