import { Div } from 'client/widget-components/basicComponents';
import Textarea from 'client/widget-components/basicComponents/Textarea';
import {
    ResponsiveStylesProp,
    mergeResponsiveStyles,
    nestResponsiveStyles,
} from 'client/widget-components/responsive-styles/responsiveStylesService';
import React from 'react';
import FieldLabel from '../FieldLabel';
import BaseCharCounter from './components/BaseCharCounter';
import {
    labelWrapperDefaultStyles,
    placeholderDefaultStyles,
    textFieldWrapperDefaultStyles,
    textareaDefaultStyles,
} from './styles';

type ComponentDataGrabs = {
    textFieldWrapperDataGrab?: string;
    labelDataGrab?: string;
    textareaDataGrab?: string;
    counterDataGrab?: string;
};

type Styles = {
    textFieldWrapper?: ResponsiveStylesProp;
    label?: ResponsiveStylesProp;
    counter?: ResponsiveStylesProp;
    textarea?: ResponsiveStylesProp;
    placeholder?: ResponsiveStylesProp;
};

export interface TextFieldProps
    extends Omit<
        React.TextareaHTMLAttributes<HTMLTextAreaElement>,
        'onChange'
    > {
    value?: string;
    label?: string;
    showCounter?: boolean;
    dataAuto?: string;
    onChange?: (
        value: string,
        event: React.ChangeEvent<HTMLTextAreaElement>
    ) => void;
    dataGrabs?: ComponentDataGrabs;
    styles?: Styles;
    labelElement?: React.ReactNode;
    charCounterElement?: React.ReactNode;
    textareaElement?: React.ReactNode;
}

function TextField({
    name,
    id = name,
    value = '',
    label = '',
    showCounter = false,
    styles,
    dataGrabs,
    dataAuto = 'text-field',
    onChange,
    labelElement,
    charCounterElement,
    textareaElement,
    ...restFieldProps
}: TextFieldProps) {
    const placeholderStyles = mergeResponsiveStyles([
        placeholderDefaultStyles,
        styles?.placeholder,
    ]);

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        onChange?.(event.target.value, event);
    };

    const labelEl =
        label &&
        (labelElement || (
            <FieldLabel
                text={label}
                styles={styles?.label}
                htmlFor={id}
                dataGrab={dataGrabs?.labelDataGrab}
            />
        ));

    const charCounterEl =
        showCounter &&
        (charCounterElement || (
            <BaseCharCounter
                valueLength={value.length}
                dataGrab={dataGrabs?.counterDataGrab}
                styles={styles?.counter}
                dataAuto={`${dataAuto}-counter`}
            />
        ));

    const textareaEl = textareaElement || (
        <Textarea
            id={id}
            name={name}
            value={value}
            data-grab={dataGrabs?.textareaDataGrab}
            styles={[
                textareaDefaultStyles,
                styles?.textarea,
                nestResponsiveStyles(placeholderStyles, {
                    innerSelector: '&::placeholder',
                }),
            ]}
            increaseSpecificity
            onChange={handleChange}
            {...restFieldProps}
        />
    );

    return (
        <Div
            data-auto={dataAuto}
            styles={[textFieldWrapperDefaultStyles, styles?.textFieldWrapper]}
            data-grab={dataGrabs?.textFieldWrapperDataGrab}
        >
            <Div styles={labelWrapperDefaultStyles}>
                {labelEl}
                {charCounterEl}
            </Div>
            {textareaEl}
        </Div>
    );
}

export default TextField;
